import { ChevronDownChunky as ChevronDownChunkySvg } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import random from 'lodash/random';
import React from 'react';
import NIYT1 from '../static/images/home/1_4K_1920x544.webp';
import NIYTMobile1 from '../static/images/home/1_mobile_659x494.webp';
import NIYTTablet1 from '../static/images/home/1_tablet_1067x470.webp';
import NIYTDesktop1 from '../static/images/home/1_web_1919x543.webp';
import NIYT2 from '../static/images/home/2_4K_1920x544.webp';
import NIYTMobile2 from '../static/images/home/2_mobile_659x494.webp';
import NIYTTablet2 from '../static/images/home/2_tablet_1067x470.webp';
import NIYTDesktop2 from '../static/images/home/2_web_1919x543.webp';
import NIYT3 from '../static/images/home/3_4K_1920x544.webp';
import NIYTMobile3 from '../static/images/home/3_mobile_659x494.webp';
import NIYTTablet3 from '../static/images/home/3_tablet_1067x470.webp';
import NIYTDesktop3 from '../static/images/home/3_web_1919x543.webp';
import NIYT4 from '../static/images/home/4_4K_1920x544.webp';
import NIYTMobile4 from '../static/images/home/4_mobile_659x494.webp';
import NIYTTablet4 from '../static/images/home/4_tablet_1067x470.webp';
import NIYTDesktop4 from '../static/images/home/4_web_1919x543.webp';
import CITYBROWSEDesktop from '../static/images/home/autumn_citybrowse_desktop.webp';
import CITYBROWSEMobile from '../static/images/home/autumn_citybrowse_mobile.webp';
import CITYBROWSETablet from '../static/images/home/autumn_citybrowse_tablet.webp';
import RAVENIGHTLIFEDesktop from '../static/images/home/rave_nightlife_desktop.webp';
import RAVENIGHTLIFEMobile from '../static/images/home/rave_nightlife_mobile.webp';
import RAVENIGHTLIFETablet from '../static/images/home/rave_nightlife_tablet.webp';
import AUS_HOLIDAYDesktop from '../static/images/home/seasonal/aus_holiday_category_desktop.webp';
import AUS_HOLIDAYMobile from '../static/images/home/seasonal/aus_holiday_category_mobile.webp';
import AUS_HOLIDAYTablet from '../static/images/home/seasonal/aus_holiday_category_tablet.webp';
import AUS_HOLIDAY_CITY_Desktop from '../static/images/home/seasonal/aus_holiday_city_desktop.webp';
import AUS_HOLIDAY_CITY_Mobile from '../static/images/home/seasonal/aus_holiday_city_mobile.webp';
import AUS_HOLIDAY_CITY_Tablet from '../static/images/home/seasonal/aus_holiday_city_tablet.webp';
import HOLIDAYDesktop from '../static/images/home/seasonal/holiday_category_desktop.webp';
import HOLIDAYMobile from '../static/images/home/seasonal/holiday_category_mobile.webp';
import HOLIDAYTablet from '../static/images/home/seasonal/holiday_category_tablet.webp';
import HOLIDAY_CITY_Desktop from '../static/images/home/seasonal/holiday_city_desktop.webp';
import HOLIDAY_CITY_Mobile from '../static/images/home/seasonal/holiday_city_mobile.webp';
import HOLIDAY_CITY_Tablet from '../static/images/home/seasonal/holiday_city_tablet.webp';
import HOLIDAY_NIGHTLIFE_Desktop from '../static/images/home/seasonal/holiday_nightlife_desktop.webp';
import HOLIDAY_NIGHTLIFE_Mobile from '../static/images/home/seasonal/holiday_nightlife_mobile.webp';
import HOLIDAY_NIGHTLIFE_Tablet from '../static/images/home/seasonal/holiday_nightlife_tablet.webp';

export const ORGANIZER_ARTICLE_TYPE = 'organizer_article';
export const EVENT_TYPE = 'any';

// Buckets
export const AUTO_BOAT_AND_AIR_EVENTS = 'auto_boat_and_air_events';
export const BASED_ON_USER_SAVED_EVENTS = 'based_on_user_saved_events';
export const BEST_OF_CITY_EVENTS = 'best_of_city_events';
export const BUSINESS_AND_PROFESSIONAL_EVENTS =
    'business_and_professional_events';
export const CHARITY_AND_CAUSES_EVENTS = 'charity_and_causes_events';
export const COMMUNITY_AND_CULTURE_EVENTS = 'community_and_culture_events';
export const DISCOUNTED_EVENTS = 'discounted_events';
export const EDITORIAL_ARTICLES = 'editorial_articles';
export const FAMILY_AND_EDUCATION_EVENTS = 'family_and_education_events';
export const FASHION_AND_BEAUTY_EVENTS = 'fashion_and_beauty_events';
export const FEATURED_ARTICLES = 'featured_articles';
export const FILM_MEDIA_AND_ENTERTAINMENT_EVENTS =
    'film_media_and_entertainment_events';
export const FOLLOWED_PROFILES = 'followed_profiles';
export const RECOMMENDED_EVENTS = 'recommended_events';
export const FOOD_AND_DRINK_EVENTS = 'food_and_drink_events';
export const FREE_EVENTS = 'free_events';
export const FROM_ATTENDED_EVENT = 'from_attended_event';
export const FROM_FOLLOWED_ORGANIZER = 'from_followed_organizer';
export const GOVERNMENT_AND_POLITICS_EVENTS = 'government_and_politics_events';
export const HEALTH_AND_WELLNESS_EVENTS = 'health_and_wellness_events';
export const HOBBIES_AND_SPECIAL_INTEREST_EVENTS =
    'hobbies_and_special_interest_events';
export const HOME_AND_LIFESTYLE_EVENTS = 'home_and_lifestyle_events';
export const LIKED_EVENTS = 'liked_events';
export const MUSIC_EVENTS = 'music_events';
export const NEARBY_CITIES = 'nearby_cities';
export const NEARBY_CITY_EVENTS = 'nearby_city_events';
export const NEW_UNDERPERFORMING_EVENTS = 'new_underperforming_events';
export const ONLINE_EVENTS = 'online_events';
export const ORGANIZERS_TO_FOLLOW = 'organizers_to_follow';
export const OTHER_CATEGORIES = 'other_categories';
export const OTHER_EVENTS = 'other_events';
export const PERFORMING_AND_VISUAL_ARTS_EVENTS =
    'performing_and_visual_arts_events';
export const PERSONALIZED_EVENTS = 'personalized_events';
export const PERSONALIZED_FOOD_AND_DRINK_EVENTS =
    'personalized_food_and_drink_events';
export const PERSONALIZED_MUSIC_EVENTS = 'personalized_music_events';
export const POPULAR_EVENTS = 'popular_events';
export const POPULAR_PROFILES = 'popular_profiles';
export const RELIGION_AND_SPIRITUALITY_EVENTS =
    'religion_and_spirituality_events';
export const SCHOOL_ACTIVITIES_EVENTS = 'school_activities_events';
export const SCIENCE_AND_TECHNOLOGY_EVENTS = 'science_and_technology_events';
export const SEASONAL_AND_HOLIDAY_EVENTS = 'seasonal_and_holiday_events';
export const SMALL_CITY_FALLBACK = 'small_city_fallback';
export const SPORTS_AND_FITNESS_EVENTS = 'sports_and_fitness_events';
export const THIS_WEEKEND = 'this_weekend';
export const TOP_PICKS_EVENTS = 'top_picks_events';
export const TRAVEL_AND_OUTDOOR_EVENTS = 'travel_and_outdoor_events';
export const TRENDING_TOPIC_EVENTS = 'trending_topic_events';
export const USER_INTERACTIONS_LIKED_INPERSON =
    'user_interactions_liked_inperson';
export const USER_INTERACTIONS_ORDERS_INPERSON =
    'user_interactions_orders_inperson';
export const USER_INTERESTS_INPERSON_EVENTS = 'user_interests_inperson_events';
export const USER_INTERACTIONS_LIKED_ONLINE = 'user_interactions_liked_online';
export const USER_INTERACTIONS_VIEWED_INPERSON =
    'user_interactions_viewed_inperson';
export const USER_INTERACTIONS_ORDERS_ONLINE =
    'user_interactions_orders_online';
export const USER_INTERACTIONS_VIEWED_ONLINE =
    'user_interactions_viewed_online';
export const USER_INTERESTS_ONLINE_EVENTS = 'user_interests_online_events';
export const USER_ORGANIZERS_FOLLOWING_INPERSON_EVENTS =
    'user_organizers_following_inperson_events';
export const USER_SAVED_EVENTS = 'user_saved_events';
export const USER_WEEKEND_INPERSON_EVENTS = 'user_weekend_inperson_events';

// Project X buckets
export const PRIMARY_BUCKET = 'primary_bucket';
export const SECONDARY_BUCKET = 'secondary_bucket';

export const BUCKET_TYPE_PROFILE = 'profile';

export const BUCKETS_NAMES = [
    AUTO_BOAT_AND_AIR_EVENTS,
    BASED_ON_USER_SAVED_EVENTS,
    BEST_OF_CITY_EVENTS,
    BUSINESS_AND_PROFESSIONAL_EVENTS,
    CHARITY_AND_CAUSES_EVENTS,
    COMMUNITY_AND_CULTURE_EVENTS,
    DISCOUNTED_EVENTS,
    FAMILY_AND_EDUCATION_EVENTS,
    FASHION_AND_BEAUTY_EVENTS,
    FILM_MEDIA_AND_ENTERTAINMENT_EVENTS,
    FOLLOWED_PROFILES,
    FOOD_AND_DRINK_EVENTS,
    FREE_EVENTS,
    FROM_ATTENDED_EVENT,
    GOVERNMENT_AND_POLITICS_EVENTS,
    HEALTH_AND_WELLNESS_EVENTS,
    HOBBIES_AND_SPECIAL_INTEREST_EVENTS,
    HOME_AND_LIFESTYLE_EVENTS,
    LIKED_EVENTS,
    MUSIC_EVENTS,
    NEARBY_CITIES,
    NEARBY_CITY_EVENTS,
    NEW_UNDERPERFORMING_EVENTS,
    ONLINE_EVENTS,
    OTHER_EVENTS,
    PERFORMING_AND_VISUAL_ARTS_EVENTS,
    PERSONALIZED_FOOD_AND_DRINK_EVENTS,
    PERSONALIZED_MUSIC_EVENTS,
    POPULAR_EVENTS,
    RECOMMENDED_EVENTS,
    RELIGION_AND_SPIRITUALITY_EVENTS,
    SCHOOL_ACTIVITIES_EVENTS,
    SCIENCE_AND_TECHNOLOGY_EVENTS,
    SEASONAL_AND_HOLIDAY_EVENTS,
    SMALL_CITY_FALLBACK,
    SPORTS_AND_FITNESS_EVENTS,
    THIS_WEEKEND,
    TRAVEL_AND_OUTDOOR_EVENTS,
    TRENDING_TOPIC_EVENTS,
    USER_INTERACTIONS_LIKED_INPERSON,
    USER_INTERACTIONS_ORDERS_INPERSON,
    USER_INTERESTS_INPERSON_EVENTS,
    USER_INTERACTIONS_LIKED_ONLINE,
    USER_INTERACTIONS_ORDERS_ONLINE,
    USER_INTERACTIONS_VIEWED_INPERSON,
    USER_INTERACTIONS_VIEWED_ONLINE,
    USER_INTERESTS_ONLINE_EVENTS,
    USER_ORGANIZERS_FOLLOWING_INPERSON_EVENTS,
    USER_SAVED_EVENTS,
    USER_SAVED_EVENTS,
    USER_WEEKEND_INPERSON_EVENTS,
];

export const BOOKMARK_EVENT_FEED = 'bookmark_event_feed';
export const RUN_SEARCH_HEADER = 'run_search_header';
export const SEE_MORE_FEED = 'see_more_feed';
export const VIEW_EVENT_FEED = 'view_event_feed';

// Madlibs
export const UPDATE_FEATURED_EVENTS = 'UPDATE_FEATURED_EVENTS';

// Moods options

export const DATE_TAG_TODAY = 'today';
export const DATE_TAG_TOMORROW = 'tomorrow';
export const DATE_TAG_THIS_WEEKEND = 'this_weekend';
export const DATE_TAG_THIS_WEEK = 'this_week';
export const DATE_TAG_NEXT_WEEK = 'next_week';
export const DATE_TAG_THIS_MONTH = 'this_month';
export const DATE_TAG_NEXT_MONTH = 'next_month';
export const DATE_TAG_ANY_DATE = 'current_future';
export const DATE_TAG_CUSTOM_DATE = 'date';
export const DATE_TAG_PICK_A_DATE = 'custom_date';

export const DATES_TAGS = [
    {
        key: DATE_TAG_TODAY,
        name: gettext('Today'),
    },
    {
        key: DATE_TAG_THIS_WEEKEND,
        name: gettext('This weekend'),
    },
    {
        key: DATE_TAG_NEXT_WEEK,
        name: gettext('Next week'),
    },
    {
        key: DATE_TAG_THIS_MONTH,
        name: gettext('This month'),
    },
    {
        key: DATE_TAG_CUSTOM_DATE,
        name: gettext('Pick a date'),
        iconType: <ChevronDownChunkySvg />,
    },
];

export const CATEGORY_DROPDOWN_SELECT = 'category_select';

// Search
export const SEARCH_SECTION = 'search';
export const SEARCH_AUTOCOMPLETE_SUGGESTIONS_URL =
    '/api/v3/destination/search/autocomplete/';
export const UPDATE_SEARCH_SUGGESTIONS = 'UPDATE_SEARCH_SUGGESTIONS';
export const SEARCH_QUERY_MAX_LENGTH = 1000;

// Empty State
export const DEFAULT_EMPTY_STATE = 'defaultEmptyState';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const SHOULD_SUGGEST_RELOADING_PAGE = 'shouldSuggestReloadingPage';

// Images
export const ONLINE_BANNER_URL =
    'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F99891082%2F174963144412%2F1%2Foriginal.20200430-164830?w=1016&auto=format%2Ccompress&q=75&sharp=10&s=be5cc9aa4646e8daeb55c374bc923a05';

export const RACIAL_EDUCATION_IMAGE_URL =
    'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F106046188%2F224447613814%2F1%2Foriginal.20200713-230017?w=1016&auto=format%2Ccompress&q=75&sharp=10&s=d44a109462fa3829c6e0dc9406de2268';

export const BLM_IMAGE_URL =
    'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F106046394%2F224447613814%2F1%2Foriginal.20200713-230327?w=1024&auto=format%2Ccompress&q=75&sharp=10&s=a3b5e9b898a34d93ea2914b6cb894172';

export const BLACK_HISTORY_MONTH_HEADER_US_CA =
    'https://eventbrite-s3.s3.amazonaws.com/marketing/homepage/BHM-hp.png';

export const IN_PERSON_EVENTS_AU_NZ =
    'https://cdn.evbstatic.com/s3-build/perm_001/4b4dd7/django/images/homefeed/header-AU-NZ.jpg';

export const BEST_UK_AND_IRELAND_EVENTS =
    'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F116773365%2F252646217916%2F1%2Foriginal.20201104-172231?w=1024&auto=format%2Ccompress&q=75&sharp=10&s=fb26280a9407521a865dddf2a78fb9dc';

export const BEST_ONLINE_COLLECTIONS_US =
    'https://cdn.evbstatic.com/s3-build/perm_001/9cba8b/django/images/homefeed/best-online-collections-US.jpeg';

export const BEST_ONLINE_COLLECTIONS_US_HD =
    'https://eventbrite-s3.s3.amazonaws.com/marketing/homepage/onilne-events-banner-2X.jpg';

export const RECONVENE =
    'https://eventbrite-s3.s3.amazonaws.com/marketing/homepage/Eventbrite-RECONVENE.jpg';

export const LIVE_EVENTS_ARE_BACK_US =
    'https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F136684873%2F174963144412%2F1%2Foriginal.20210526-163523?w=1024&auto=format%2Ccompress&q=75&sharp=10&s=99dd82aaf21c8a90b4221fdeca0517c6';

export const DOT_WIDTH = 10;
export const DOT_HEIGHT = 8;
export const DOT_FILL_ACTIVE = '#716B7A';
export const DOT_FILL_INACTIVE = 'white';
export const DOT_RADIUS = 4;

export const NIYT_1 = {
    mobile: NIYTMobile1,
    tablet: NIYTTablet1,
    desktop: NIYTDesktop1,
    twok: NIYT1,
};

export const NIYT_2 = {
    mobile: NIYTMobile2,
    tablet: NIYTTablet2,
    desktop: NIYTDesktop2,
    twok: NIYT2,
};

export const NIYT_3 = {
    mobile: NIYTMobile3,
    tablet: NIYTTablet3,
    desktop: NIYTDesktop3,
    twok: NIYT3,
};

export const NIYT_4 = {
    mobile: NIYTMobile4,
    tablet: NIYTTablet4,
    desktop: NIYTDesktop4,
    twok: NIYT4,
};

export interface FullbleedHeaderData {
    imageSet: {
        mobile: string;
        tablet: string;
        desktop: string;
    };
    altText: string;
    instrumentationText: string;
    ctaLink: string;
}

export const HOMEPAGE_STANDARD_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: CITYBROWSEMobile,
        tablet: CITYBROWSETablet,
        desktop: CITYBROWSEDesktop,
    },
    altText: 'Standard Banner',
    instrumentationText: 'standard',
    ctaLink: '/d/local/events',
};

export const HOMEPAGE_NIGHTLIFE_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: RAVENIGHTLIFEMobile,
        tablet: RAVENIGHTLIFETablet,
        desktop: RAVENIGHTLIFEDesktop,
    },
    altText: 'Nightlife Banner',
    instrumentationText: 'nightlife',
    ctaLink: '/b/local/nightlife/',
};

export const HOLIDAY_CATEGORY_BANNER_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: HOLIDAYMobile,
        tablet: HOLIDAYTablet,
        desktop: HOLIDAYDesktop,
    },
    altText: 'Holiday Category Banner',
    instrumentationText: 'holiday_cat',
    ctaLink: '/b/local/holiday/',
};

export const HOLIDAY_CITY_BANNER_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: HOLIDAY_CITY_Mobile,
        tablet: HOLIDAY_CITY_Tablet,
        desktop: HOLIDAY_CITY_Desktop,
    },
    altText: 'Holiday City Banner',
    instrumentationText: 'holiday_city',
    ctaLink: '/d/local/events',
};

export const HOLIDAY_NIGHTLIFE_BANNER_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: HOLIDAY_NIGHTLIFE_Mobile,
        tablet: HOLIDAY_NIGHTLIFE_Tablet,
        desktop: HOLIDAY_NIGHTLIFE_Desktop,
    },
    altText: 'Holiday Nightlife Banner',
    instrumentationText: 'holiday_nightlife',
    ctaLink: '/b/local/nightlife/',
};

export const AUS_HOLIDAY_CATEGORY_BANNER_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: AUS_HOLIDAYMobile,
        tablet: AUS_HOLIDAYTablet,
        desktop: AUS_HOLIDAYDesktop,
    },
    altText: 'AUS Holiday Category Banner',
    instrumentationText: 'holiday_cat_aus',
    ctaLink: '/b/local/holiday/',
};

export const AUS_HOLIDAY_CITY_BANNER_DATA: FullbleedHeaderData = {
    imageSet: {
        mobile: AUS_HOLIDAY_CITY_Mobile,
        tablet: AUS_HOLIDAY_CITY_Tablet,
        desktop: AUS_HOLIDAY_CITY_Desktop,
    },
    altText: 'AUS Holiday City Banner',
    instrumentationText: 'holiday_city_aus',
    ctaLink: '/d/local/events',
};

export const fullbleedHeaderDataList: FullbleedHeaderData[] = [
    HOMEPAGE_STANDARD_DATA,
    HOMEPAGE_NIGHTLIFE_DATA,
];

export const holidayHeaderDataList: FullbleedHeaderData[] = [
    HOLIDAY_CATEGORY_BANNER_DATA,
    HOLIDAY_CITY_BANNER_DATA,
    HOLIDAY_NIGHTLIFE_BANNER_DATA,
];

export const aus_holidayHeaderDataList: FullbleedHeaderData[] = [
    AUS_HOLIDAY_CATEGORY_BANNER_DATA,
    AUS_HOLIDAY_CITY_BANNER_DATA,
];

export const fullbleedHeaderImageList = [NIYT_1, NIYT_2, NIYT_3, NIYT_4];

// For now this is the easiest way i've found to get random images on the header without changing the existing code very much
const fullbleedImageSetSelector = (
    fullbleedHeaderImageList: string | any[],
) => {
    const randomIndex = random(fullbleedHeaderImageList.length - 1);
    return fullbleedHeaderImageList[randomIndex];
};

export const fullbleedHeaderImageSet = fullbleedImageSetSelector(
    fullbleedHeaderImageList,
);

// TLDs
export const TLD_ARGENTINA = '.com.ar';
export const TLD_AUSTRALIA = '.com.au';
export const TLD_BELGIQUE = '.be';
export const TLD_BRASIL = '.com.br';
export const TLD_CANADA = '.ca';
export const TLD_CHILE = '.cl';
export const TLD_COLOMBIA = '.co';
export const TLD_DENMARK = '.dk';
export const TLD_DEUTSCHLAND = '.de';
export const TLD_ESPANA = '.es';
export const TLD_FINLAND = '.fi';
export const TLD_FRANCE = '.fr';
export const TLD_HONG_KONG = '.hk';
export const TLD_IRELAND = '.ie';
export const TLD_ITALIA = '.it';
export const TLD_MEXICO = '.com.mx';
export const TLD_NEDERLAND = '.nl';
export const TLD_NEW_ZEALAND = '.co.nz';
export const TLD_OSTERREICH = '.at';
export const TLD_PERU = '.com.pe';
export const TLD_PORTUGAL = '.pt';
export const TLD_SCHWEIZ = '.ch';
export const TLD_SINGAPORE = '.sg';
export const TLD_SVERIGE = '.se';
export const TLD_UNITED_KINGDOM = '.co.uk';
export const TLD_UNITED_STATES = '.com';
export const TLD_GLOBALLY = 'global';

export const ALL_TLDs = [
    TLD_ARGENTINA,
    TLD_AUSTRALIA,
    TLD_BELGIQUE,
    TLD_BRASIL,
    TLD_CANADA,
    TLD_CHILE,
    TLD_COLOMBIA,
    TLD_DENMARK,
    TLD_DEUTSCHLAND,
    TLD_ESPANA,
    TLD_FINLAND,
    TLD_FRANCE,
    TLD_HONG_KONG,
    TLD_IRELAND,
    TLD_ITALIA,
    TLD_MEXICO,
    TLD_NEDERLAND,
    TLD_NEW_ZEALAND,
    TLD_OSTERREICH,
    TLD_PERU,
    TLD_PORTUGAL,
    TLD_SCHWEIZ,
    TLD_SINGAPORE,
    TLD_SVERIGE,
    TLD_UNITED_KINGDOM,
    TLD_UNITED_STATES,
] as const;
export const ENGLISH_TLDs = [
    TLD_CANADA,
    TLD_NEW_ZEALAND,
    TLD_AUSTRALIA,
    TLD_UNITED_KINGDOM,
    TLD_IRELAND,
    TLD_UNITED_STATES,
] as const;
